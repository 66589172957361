import { createAction } from "../redux-helpers";

export const PLAN_CHOICES_UPDATE = "PLAN_CHOICES_UPDATE";
export const ENTITLEMENTS_UPDATE = "ENTITLEMENTS_UPDATE";
export const ENTITLEMENTS_STATUS_UPDATE = "ENTITLEMENTS_STATUS_UPDATE";
export const PLANS_STATUS_UPDATE = "PLANS_STATUS_UPDATE";

export const planChoicesUpdate = createAction(PLAN_CHOICES_UPDATE);
export const entitlementsUpdate = createAction(ENTITLEMENTS_UPDATE);
export const entitlementsStatusUpdate = createAction(ENTITLEMENTS_STATUS_UPDATE);
export const planStatusUpdate = createAction(PLANS_STATUS_UPDATE);
