import AxiosInstance from "module/common/utils/axiosConfig";
import { PlanIds } from "./interfaces";

export const getEntitlementsOptions = async (companyId: number) =>
  AxiosInstance.options(`/companies/${companyId}/active_entitlements/`);

interface IEntitlementsRequest {
  planId: PlanIds;
}

export const putEntitlementsPlan = async (
  companyId: number,
  request: IEntitlementsRequest
) => AxiosInstance.put(`/companies/${companyId}/active_entitlements/`, request);

export const getCompanyEntitlements = (companyId: number) => {
  return AxiosInstance.get(`/companies/${companyId}/active_entitlements/`);
};
