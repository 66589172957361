import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Input, UncontrolledTooltip } from "reactstrap";
import styled from "styled-components/macro";

// utils
import { IPlan } from "module/main/store/SubscriptionStore/interfaces";
import {
  formatCurrency,
  formatNumber,
  formatSpecialCurrency,
} from "../../../helpers/utils";

interface Props {
  plan: IPlan;
  onSelectedSubscription?: (subscriptionPlan: IPlan) => void;
  isCurrent?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  className?: string;
}

const SubscriptionCard: React.FC<Props> = ({
  plan,
  onSelectedSubscription,
  isCurrent = false,
  isActive = false,
  isDisabled = false,
  className = "",
}) => {
  const checkIcon = (
    <CheckCircle className="mr-1 align-self-center">
      <FontAwesomeIcon icon="check" color="var(--green)" size="lg" />
    </CheckCircle>
  );

  const cardId = `sub-card-${plan.id}`;
  const DAYS_IN_MONTH = 30;

  return (
    <StyledCard
      className={"p-1 m-2" + className}
      data-test="sub-card"
      id={cardId}
      $bolded={isCurrent}
      $isActive={isActive}
      disabled={isDisabled}
      onClick={() => {
        if (isDisabled) return;
        onSelectedSubscription && onSelectedSubscription(plan);
      }}
    >
      <h2 className="font-weight-bold mb-2" data-test="plan-title">
        {plan.description}
      </h2>
      <div className="d-flex flex-row justify-content-center">
        <StyledPricingText className="font-weight-bolder mr-1" data-test="pricing">
          {/* Not doing format currency so it doesn't have decimals */}
          {`$${formatNumber({ value: plan.price })}`}
        </StyledPricingText>
        <span className="textL text-muted align-self-center">/ mo.</span>
      </div>
      <StyledFeaturesList
        className="d-flex flex-column justify-content-start px-2 mb-1"
        data-testid="features"
      >
        <li className="textL">
          {checkIcon}{" "}
          <span>{formatSpecialCurrency(plan.maxPlanPriceColdMessageAttempt, 3)}</span> per
          outbound SMS
          {onSelectedSubscription ? "*" : ""}
        </li>
        <li className="textL d-flex">
          {checkIcon}{" "}
          <div>
            <span>
              Up to{" "}
              {formatNumber({
                value: plan.maxDailyLimitColdMessageAttempt * DAYS_IN_MONTH,
              })}{" "}
              msgs/mo
            </span>{" "}
            <br />
            {formatNumber({ value: plan.maxDailyLimitColdMessageAttempt })} msgs/day
          </div>
        </li>
        <li className="textL d-flex">
          {checkIcon}{" "}
          <div>
            <span>
              {formatCurrency(plan.maxPlanPricePropertyOwnerLookup)} to{" "}
              {formatCurrency(plan.minUnitPriceSkiptrace)}
            </span>{" "}
            per Skip Trace billable record
          </div>
        </li>
        <li className="textL d-flex">
          {checkIcon}{" "}
          <div>
            <span>Unlimited contacts</span> stacked in PropStack™
          </div>
        </li>
      </StyledFeaturesList>
      {onSelectedSubscription ? (
        <StyledInput
          className="mx-0 pointer"
          type="radio"
          disabled={isDisabled}
          checked={isCurrent}
          onChange={() => {}}
        />
      ) : null}
      {isDisabled ? (
        <UncontrolledTooltip placement="top" target={cardId} autohide={false}>
          Please contact{" "}
          <a href="mailto:support@leadsherpa.com">support@leadsherpa.com</a> in order to
          modify your current plan.
        </UncontrolledTooltip>
      ) : null}
    </StyledCard>
  );
};

const StyledInput = styled(Input)`
  position: relative;
  width: 2em;
  height: 2em;

  background-color: white;
  border-radius: 50%;
  border: 2px solid var(--primary);
  appearance: none;

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.6em;
    height: 1.6em;

    background-color: transparent;
    border-radius: 50%;
    border: 3px solid white;
    appearance: none;
  }

  :checked {
    background-color: var(--primary);
  }

  &[disabled] {
    cursor: not-allowed;
    border: 2px solid var(--disabledGray);
  }
`;

interface StyledCardProps {
  $bolded: boolean;
  $isActive: boolean;
}
const StyledCard = styled(Card)<StyledCardProps>`
  background-color: var(--white);
  border: none;
  border-radius: 30px;
  width: 240px;
  height: 380px;

  box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => (props.$bolded ? `outline: 3px solid var(--primary);` : null)}

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(props) =>
    props.$isActive
      ? `
      &:before {
        content: "Current Plan";
        font-size: 14px;
        position: absolute;
        top: -1px;
        color: var(--white);
        background-color: var(--primary);

        width: 100%;
        height: 29px;
        border-radius: 30px 30px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
  `
      : null}
`;

const StyledPricingText = styled.h1`
  color: var(--sherpaBlue);
`;

const StyledFeaturesList = styled.ul`
  color: var(--darkGray);
  align-self: start;
  list-style-type: none;
  flex-basis: 100px;
  li {
    margin-bottom: var(--pad2);
  }
  span {
    font-weight: bolder !important;
  }
`;

const CheckCircle = styled.div`
  display: inline-block;
  z-index: 0;
  height: 20px;
  width: 20px;
  padding: 1px;
  background-color: rgba(24, 206, 23, 0.3);
  border-radius: 50%;
`;

export default SubscriptionCard;
