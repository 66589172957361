import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";

import MultiStepWizard from "module/common/components/MultiStepWizard";
import { fetchUserInfo } from "module/main/store/Auth/thunks";

import { stepGroupObj } from "./utils";

const SkipTraceCreateBilling = () => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const onSuccess = () => {
    advanceToNextStep();
    // refetch user data so permissions refresh
    dispatch(fetchUserInfo());
  };

  const advanceToNextStep = () => {
    setCurrentStep((currentStep) => currentStep + 1);
  };
  const goToPreviousStep = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  const steps = useMemo(() => {
    return [
      {
        ...stepGroupObj.subscriptionOptionsStep,
        props: {
          advanceToNextStep,
          setFormData,
          isNewSignUp: true,
        },
      },
      {
        ...stepGroupObj.billingPageStep,
        props: {
          advanceToNextStep: onSuccess,
          goToPreviousStep,
          formData,
        },
      },
      {
        stepTitle: "Confirm Billing Information",
        Component: () => (
          <div className="w-100 d-flex">
            <Spinner color="primary" size="lg" className="mx-auto" />
          </div>
        ),
        group: 3,
        props: {},
      },
    ];
  }, [formData, stepGroupObj]);

  return <MultiStepWizard steps={steps} currentStep={currentStep}></MultiStepWizard>;
};

export default SkipTraceCreateBilling;
