import { produce } from "immer";
import { ApiFetchStatus } from "module/common/helpers/variables";

import {
  ENTITLEMENTS_STATUS_UPDATE,
  ENTITLEMENTS_UPDATE,
  PLANS_STATUS_UPDATE,
  PLAN_CHOICES_UPDATE,
} from "./actions";
import { IState } from "./interfaces";

export const path = ["subscriptionStore"];

const initialState: IState = {
  planChoices: null,
  entitlements: null,
  status: ApiFetchStatus.Initial,
  entitlementsStatus: ApiFetchStatus.Initial,
};

const reducer = produce((base: IState, action: any) => {
  switch (action.type) {
    case PLAN_CHOICES_UPDATE: {
      base.planChoices = action.payload;
      base.status = ApiFetchStatus.Success;
      break;
    }
    case PLANS_STATUS_UPDATE: {
      base.status = action.payload;
      break;
    }
    case ENTITLEMENTS_UPDATE: {
      base.entitlements = action.payload;
      base.entitlementsStatus = ApiFetchStatus.Success;
      break;
    }
    case ENTITLEMENTS_STATUS_UPDATE: {
      base.entitlementsStatus = action.payload;
      break;
    }
  }
}, initialState);

export default reducer;
