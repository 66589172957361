import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
// components
import { Button, Spinner } from "reactstrap";
import SubscriptionCard from "./SubscriptionCard";
// store
import { ICompany } from "module/main/store/Company/interfaces";
import { getCompanyData } from "module/main/store/Company/selectors";
import { IPlan, PlanIds } from "module/main/store/SubscriptionStore/interfaces";
// utils
import { usePlanChoices } from "./hooks";

interface Props {
  setFormData: (data: any) => void;
  advanceToNextStep: () => void;
  isNewSignUp: boolean;
}

const SubscriptionOptions: React.FC<Props> = ({
  setFormData,
  advanceToNextStep,
  isNewSignUp,
}) => {
  const company: ICompany = useSelector(getCompanyData);
  const [subscriptionOptions, isPlanLoading] = usePlanChoices(company.id);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<IPlan>(
    company.plan
  );

  const activePlanId = company.plan?.id;
  const selectedPlanId = selectedSubscriptionPlan.id;
  const onContinue = () => {
    setFormData({ subscription: { ...selectedSubscriptionPlan } });
    advanceToNextStep();
  };

  if (isPlanLoading) {
    return <Spinner color="primary" size="lg" />;
  }

  const shouldDisableContinue = () => {
    if (isNewSignUp)
      return activePlanId !== PlanIds.Free && selectedPlanId === activePlanId;

    return selectedPlanId === activePlanId;
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap justify-content-center" data-test="styled-card">
        {subscriptionOptions.estimates.map((estimate) => {
          const plan = estimate.plan;
          const isSelected = selectedPlanId === plan.id;
          return (
            <Fragment key={plan.id}>
              <SubscriptionCard
                onSelectedSubscription={setSelectedSubscriptionPlan}
                plan={plan}
                isCurrent={isSelected}
                isActive={!isNewSignUp && plan.id === activePlanId}
                isDisabled={!isNewSignUp && estimate.changeType !== "upgrade"}
              />
            </Fragment>
          );
        })}
      </div>
      <p className="text-muted align-self-center mt-4">
        *This does not include charges incurred with your service provider.
      </p>
      <p className="text-muted align-self-center">
        **Tax may be included depending on your payment method region.
      </p>
      <Button
        color="primary"
        className="mt-4 mr-2 ml-auto"
        onClick={onContinue}
        disabled={shouldDisableContinue()}
      >
        Continue
      </Button>
    </div>
  );
};

export default SubscriptionOptions;
